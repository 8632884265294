import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 3264.000000 3264.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,3264.000000) scale(0.100000,-0.100000)">
         <path d="M19295 20584 c-211 -18 -382 -43 -535 -79 -41 -10 -93 -21 -115 -25
-55 -11 -301 -77 -350 -94 -22 -8 -69 -22 -105 -31 -36 -10 -78 -23 -95 -30
-16 -6 -46 -18 -65 -25 -19 -6 -44 -16 -55 -20 -11 -5 -45 -16 -75 -26 -30 -9
-59 -20 -65 -25 -5 -5 -16 -9 -25 -9 -8 0 -26 -6 -40 -13 -14 -8 -59 -26 -100
-42 -41 -15 -103 -41 -138 -56 -35 -16 -66 -29 -68 -29 -3 0 -42 -17 -87 -38
-45 -22 -111 -51 -147 -66 -36 -15 -72 -32 -80 -36 -8 -5 -87 -46 -175 -91
-268 -138 -288 -149 -337 -179 -26 -16 -73 -43 -105 -61 -32 -18 -74 -43 -93
-56 -19 -12 -38 -23 -42 -23 -4 0 -49 -25 -100 -56 -51 -32 -105 -64 -119 -73
-15 -9 -48 -31 -73 -48 -26 -18 -51 -33 -57 -33 -5 0 -9 -4 -9 -10 0 -5 -5
-10 -10 -10 -6 0 -35 -18 -65 -40 -30 -22 -57 -40 -59 -40 -5 0 -136 -91 -181
-125 -10 -8 -23 -15 -27 -15 -4 0 -16 -8 -26 -17 -28 -26 -52 -45 -102 -77
-25 -16 -74 -52 -110 -80 -36 -27 -92 -71 -125 -96 -116 -90 -181 -141 -245
-196 -36 -30 -87 -72 -115 -93 -27 -21 -63 -51 -80 -67 -16 -16 -46 -42 -65
-57 -19 -15 -82 -72 -140 -125 -272 -251 -308 -287 -295 -300 3 -3 9 -2 13 4
4 5 27 25 52 44 25 19 56 44 68 57 13 12 44 37 68 55 25 18 69 51 97 74 29 22
70 51 92 64 22 13 49 33 60 43 11 10 61 44 110 75 50 30 127 80 172 109 44 29
84 53 87 53 4 0 35 17 69 39 139 87 684 361 767 386 14 4 57 22 95 40 39 18
106 44 150 59 44 15 94 33 110 41 40 18 102 40 200 71 44 14 87 29 95 33 19 9
164 54 250 76 36 10 76 21 90 25 44 14 133 38 205 55 95 22 132 31 183 45 24
6 67 15 95 20 29 5 81 16 117 24 36 8 92 17 125 21 33 4 87 13 120 21 33 8 79
14 102 14 24 0 62 4 85 10 201 45 942 73 1278 47 240 -19 519 -65 770 -127 85
-21 328 -103 358 -121 18 -10 39 -19 47 -19 7 0 35 -11 62 -24 26 -13 73 -36
103 -50 96 -44 270 -153 365 -229 102 -81 293 -286 359 -385 71 -107 176 -305
176 -333 0 -10 5 -21 10 -24 13 -8 13 196 -1 335 -26 266 -43 392 -58 433 -6
16 -11 40 -11 55 0 14 -6 44 -14 67 -8 22 -24 74 -36 115 -68 237 -181 469
-318 650 -63 83 -268 290 -287 290 -7 0 -18 7 -25 15 -36 43 -330 209 -405
228 -11 3 -54 17 -95 32 -83 31 -165 52 -255 66 -33 5 -67 12 -75 15 -30 12
-338 34 -455 32 -66 0 -187 -7 -270 -14z"/>
<path d="M24760 20541 c0 -5 7 -14 15 -21 8 -7 15 -21 15 -31 0 -11 11 -37 25
-59 14 -22 25 -44 25 -48 0 -8 21 -58 39 -92 5 -9 12 -30 15 -48 4 -17 12 -34
17 -37 5 -4 9 -15 9 -26 0 -10 4 -27 9 -37 32 -58 100 -309 122 -450 35 -229
53 -467 46 -597 -12 -204 -25 -350 -36 -410 -5 -33 -17 -103 -26 -155 -29
-168 -82 -373 -125 -485 -4 -11 -18 -51 -30 -90 -36 -110 -58 -172 -73 -207
-8 -18 -29 -69 -47 -113 -53 -131 -209 -456 -252 -525 -22 -36 -48 -81 -76
-135 -30 -58 -170 -284 -221 -358 -14 -21 -31 -45 -36 -55 -27 -46 -91 -141
-119 -177 -17 -22 -35 -48 -41 -57 -30 -49 -375 -503 -394 -518 -4 -3 -22 -25
-41 -50 -36 -46 -220 -262 -249 -291 -9 -9 -23 -25 -31 -35 -121 -148 -755
-800 -942 -969 -24 -22 -94 -87 -154 -145 -145 -138 -305 -283 -407 -367 -23
-19 -44 -39 -47 -42 -3 -4 -50 -45 -105 -91 -55 -46 -102 -86 -105 -90 -5 -6
-162 -138 -324 -273 -112 -93 -268 -219 -356 -287 -25 -19 -47 -37 -50 -41 -3
-3 -39 -31 -80 -61 -41 -30 -105 -79 -142 -109 -37 -30 -89 -70 -114 -89 -26
-19 -70 -53 -98 -75 -94 -75 -209 -160 -232 -173 -13 -7 -24 -17 -24 -23 0 -5
-9 -9 -19 -9 -10 0 -21 -6 -24 -13 -5 -13 -251 -197 -264 -197 -4 0 -18 -10
-32 -23 -31 -28 -248 -187 -255 -187 -4 0 -116 -82 -216 -158 -14 -10 -41 -27
-60 -38 -19 -10 -37 -21 -40 -24 -18 -18 -237 -170 -245 -170 -3 0 -11 -5 -18
-10 -36 -32 -278 -199 -316 -219 -14 -8 -28 -17 -31 -20 -3 -3 -50 -36 -105
-72 -55 -36 -188 -124 -295 -195 -107 -71 -199 -131 -205 -134 -6 -3 -22 -13
-35 -23 -14 -9 -62 -41 -107 -70 -45 -28 -116 -74 -158 -102 -43 -27 -89 -56
-104 -64 -14 -8 -28 -17 -31 -20 -5 -6 -35 -26 -134 -89 -27 -18 -55 -32 -62
-32 -7 0 -14 -4 -16 -8 -5 -12 -323 -212 -388 -244 -14 -7 -29 -18 -33 -25 -4
-7 -13 -13 -18 -13 -6 0 -25 -10 -42 -22 -36 -26 -80 -52 -117 -70 -14 -7 -27
-16 -30 -19 -3 -4 -35 -23 -72 -43 -60 -33 -102 -66 -83 -66 3 0 37 12 73 26
92 35 213 83 237 94 11 4 38 15 60 25 22 9 49 20 60 25 11 5 67 28 125 50 58
23 114 45 125 50 11 5 81 34 155 66 74 31 154 65 177 75 24 11 82 35 130 54
49 20 96 40 106 46 19 10 216 98 337 149 80 34 154 68 290 132 58 27 127 59
155 70 27 10 61 26 75 34 14 7 36 18 50 24 14 5 32 14 40 19 8 5 65 33 125 62
185 87 737 362 790 393 28 15 112 61 188 100 75 39 159 82 185 96 169 91 230
125 252 140 14 9 39 24 55 32 49 24 249 140 265 153 8 7 26 16 40 20 14 4 27
11 30 14 6 8 127 84 150 94 8 4 29 16 45 26 17 10 91 55 165 101 74 45 149 92
165 104 24 18 215 140 283 181 10 6 58 37 107 70 50 33 97 63 105 67 8 5 35
24 60 43 25 19 95 68 155 110 61 41 135 94 167 118 31 23 100 74 153 112 79
57 232 175 341 263 12 9 61 49 109 87 49 39 107 86 129 106 23 20 69 59 104
87 35 28 105 88 155 134 50 46 122 112 161 147 108 98 574 567 641 646 33 38
69 79 80 90 12 11 43 47 70 80 28 32 57 67 65 76 8 10 26 32 40 49 39 50 72
89 112 134 21 22 38 44 38 48 0 4 6 13 13 20 72 76 332 451 489 708 115 187
144 240 286 517 9 17 23 46 70 153 128 284 237 598 293 840 11 51 47 217 58
275 57 286 75 715 42 1020 -14 126 -38 293 -45 303 -3 5 -9 33 -15 63 -16 94
-91 358 -136 479 -47 129 -61 158 -161 365 -71 146 -162 306 -220 388 -19 26
-34 50 -34 53 0 9 -131 189 -141 192 -5 2 -9 10 -9 18 0 8 -4 14 -10 14 -5 0
-10 -4 -10 -9z"/>
<path d="M9684 17968 c-37 -57 -71 -105 -74 -108 -4 -3 -10 -13 -13 -23 -4
-11 -58 -96 -120 -190 -63 -95 -122 -187 -133 -204 -10 -18 -22 -33 -26 -33
-4 0 -8 -7 -8 -16 0 -9 -9 -28 -21 -42 -19 -25 -189 -283 -189 -287 0 -3 -85
-133 -107 -165 -10 -14 -58 -88 -106 -165 -49 -77 -97 -149 -108 -161 -10 -11
-19 -24 -19 -28 0 -8 -64 -110 -111 -178 -11 -15 -19 -30 -19 -33 0 -3 -18
-30 -40 -60 -22 -30 -40 -57 -40 -61 0 -4 -6 -15 -14 -23 -8 -9 -21 -27 -28
-41 -7 -14 -53 -86 -102 -160 -107 -162 -163 -248 -205 -315 -17 -27 -45 -70
-62 -95 -49 -72 -219 -333 -219 -338 0 -2 -21 -36 -47 -75 -26 -40 -52 -80
-58 -90 -41 -70 -111 -173 -122 -180 -7 -4 -13 -14 -13 -21 0 -8 -37 -69 -82
-136 -113 -167 -117 -173 -125 -190 -9 -19 -119 -187 -144 -220 -10 -14 -29
-43 -40 -65 -12 -22 -61 -98 -109 -170 -47 -71 -102 -155 -120 -185 -19 -30
-38 -56 -42 -58 -5 -2 -8 -8 -8 -14 0 -9 -40 -74 -106 -172 -10 -16 -24 -36
-29 -45 -6 -9 -37 -56 -70 -106 -33 -49 -62 -94 -65 -100 -3 -5 -27 -44 -54
-85 -62 -96 -123 -190 -170 -262 -20 -32 -41 -58 -47 -58 -5 0 -9 -6 -9 -14 0
-8 -16 -38 -35 -67 -19 -29 -31 -55 -26 -59 5 -3 327 -4 715 -2 l707 2 9 23
c5 12 23 40 39 62 17 22 35 51 41 65 5 14 13 30 17 36 30 44 48 71 109 167 38
59 77 120 86 135 10 15 22 36 28 47 6 11 24 40 40 65 16 25 39 61 50 80 33 57
44 73 68 103 12 16 22 32 22 37 0 9 40 73 60 98 9 9 210 12 946 12 l934 0 10
-22 c5 -13 16 -106 25 -208 9 -102 20 -225 25 -275 5 -49 15 -160 21 -245 7
-85 17 -161 23 -167 16 -18 1406 -19 1417 -2 4 7 5 26 3 43 -2 17 -10 96 -19
176 -8 80 -19 181 -24 225 -6 44 -17 152 -26 240 -9 88 -23 210 -31 270 -8 61
-14 134 -14 163 0 29 -4 60 -9 70 -5 9 -14 76 -21 147 -6 72 -15 166 -21 210
-5 44 -16 141 -24 215 -8 74 -19 173 -25 220 -5 47 -14 135 -20 195 -6 61 -15
144 -20 185 -11 86 -32 275 -55 500 -9 85 -20 191 -25 235 -17 146 -40 339
-50 420 -6 44 -15 125 -20 180 -6 55 -15 141 -20 190 -6 50 -17 155 -25 235
-8 80 -22 201 -31 270 -9 69 -20 172 -24 230 -5 58 -16 166 -25 240 -9 74 -20
175 -24 223 -5 59 -12 92 -22 98 -8 5 -329 9 -741 9 l-727 0 -67 -102z m400
-1486 c3 -10 7 -69 11 -132 3 -63 10 -171 15 -240 5 -69 14 -186 20 -260 5
-74 17 -220 25 -325 8 -104 20 -273 26 -375 6 -102 15 -225 19 -275 5 -68 4
-95 -7 -112 l-13 -22 -579 -1 c-510 0 -580 2 -591 15 -16 19 -5 44 57 132 13
17 23 36 23 41 0 5 17 33 38 63 20 30 50 79 66 109 17 30 39 69 50 87 62 94
117 184 141 228 16 28 40 68 55 90 14 22 48 76 74 120 94 157 153 254 163 267
6 7 25 40 43 73 18 33 43 74 56 92 13 17 24 34 24 38 0 3 51 87 113 188 63
100 119 192 126 204 12 24 37 21 45 -5z"/>
<path d="M11794 18056 c-6 -16 37 -206 67 -296 4 -14 11 -45 14 -70 3 -25 10
-54 14 -65 5 -11 16 -56 26 -100 10 -44 26 -106 36 -138 10 -32 21 -75 25 -95
3 -20 12 -59 20 -87 31 -112 92 -365 113 -470 11 -55 32 -136 41 -160 15 -38
33 -110 40 -155 4 -25 17 -81 29 -124 11 -44 21 -89 21 -102 0 -13 4 -25 9
-28 5 -3 12 -23 15 -43 4 -21 11 -51 16 -68 5 -16 16 -66 25 -110 10 -44 22
-93 27 -110 6 -16 14 -46 18 -65 5 -19 20 -82 35 -140 14 -58 33 -132 41 -165
8 -33 19 -76 24 -95 12 -49 39 -163 56 -240 8 -36 19 -81 23 -100 70 -281 136
-543 156 -617 33 -122 40 -189 25 -238 -6 -22 -15 -56 -20 -75 -5 -19 -22 -84
-38 -145 -16 -60 -39 -150 -51 -200 -12 -49 -26 -98 -32 -107 -5 -10 -9 -26
-9 -35 0 -10 -6 -35 -14 -58 -13 -37 -25 -83 -56 -210 -7 -27 -21 -81 -32
-120 -60 -215 -92 -345 -85 -351 3 -3 294 -5 648 -4 494 0 646 3 655 13 7 6
19 39 28 72 9 33 25 94 37 135 44 157 80 297 95 363 8 38 20 81 26 95 6 15 14
43 18 62 15 68 60 235 69 255 6 11 12 34 15 50 6 35 50 207 71 282 9 28 26 64
39 80 57 66 126 154 193 243 67 90 162 210 270 344 26 33 93 118 148 190 94
123 144 188 305 390 36 45 92 117 125 161 32 44 75 99 95 122 19 23 90 113
158 200 222 287 371 478 384 493 7 9 33 41 57 73 24 31 47 57 52 57 5 0 9 7 9
15 0 9 7 18 15 21 8 4 15 11 15 16 0 5 19 33 43 61 24 29 52 64 63 78 49 68
221 288 253 325 20 23 59 72 86 109 28 37 55 72 60 78 29 35 128 160 166 212
24 33 46 62 49 65 6 6 54 66 221 280 66 85 128 164 138 175 26 32 51 69 51 77
0 5 -309 8 -687 8 l-688 0 -55 -75 c-30 -42 -66 -90 -80 -109 -14 -18 -58 -78
-99 -132 -41 -54 -86 -113 -100 -131 -14 -18 -34 -42 -43 -55 -68 -91 -115
-154 -128 -172 -32 -47 -74 -101 -85 -111 -6 -5 -21 -27 -34 -47 -12 -21 -27
-38 -32 -38 -5 0 -9 -4 -9 -10 0 -12 -129 -182 -147 -193 -7 -4 -13 -13 -13
-18 0 -8 -98 -142 -184 -250 -12 -14 -28 -36 -36 -49 -14 -20 -86 -116 -113
-150 -7 -8 -21 -29 -31 -45 -11 -17 -25 -34 -32 -40 -7 -5 -49 -62 -95 -125
-45 -63 -95 -131 -111 -150 -16 -19 -66 -84 -111 -145 -45 -60 -122 -163 -172
-229 -49 -65 -100 -133 -111 -150 -12 -17 -37 -49 -56 -70 l-34 -39 -18 28
c-10 15 -22 56 -27 92 -5 36 -12 70 -16 76 -4 6 -10 29 -14 52 -7 43 -19 98
-44 210 -9 39 -22 104 -30 145 -8 41 -22 107 -30 145 -9 39 -20 90 -25 115
-17 82 -36 165 -42 180 -4 8 -9 31 -12 50 -3 19 -17 89 -32 155 -44 201 -51
236 -73 350 -12 61 -28 133 -36 161 -8 28 -15 64 -15 80 0 17 -4 34 -9 40 -4
5 -16 50 -25 99 -10 50 -22 98 -27 107 -5 10 -9 27 -9 38 0 12 -6 49 -14 83
-8 34 -22 98 -31 142 -9 44 -22 103 -28 130 l-12 50 -688 3 c-588 2 -688 0
-693 -12z"/>
<path d="M18345 18060 c-27 -5 -77 -11 -110 -14 -130 -14 -409 -86 -483 -125
-9 -5 -57 -29 -107 -53 -97 -46 -240 -143 -320 -217 -44 -41 -76 -75 -175
-187 -15 -17 -38 -49 -51 -71 -13 -23 -47 -78 -75 -124 -56 -92 -83 -143 -109
-204 -9 -22 -22 -53 -30 -70 -38 -86 -49 -114 -62 -155 -51 -160 -67 -215 -88
-295 -13 -49 -31 -115 -40 -145 -9 -30 -20 -76 -25 -102 -5 -27 -11 -52 -14
-57 -3 -5 -18 -80 -32 -167 -32 -187 -39 -427 -15 -529 40 -177 90 -280 182
-376 49 -51 152 -129 170 -129 6 0 26 -9 44 -20 79 -49 262 -97 460 -120 144
-18 234 -20 920 -20 813 -1 849 -3 935 -51 58 -32 90 -92 90 -168 0 -62 -33
-272 -50 -316 -6 -15 -15 -47 -20 -71 -42 -189 -219 -358 -395 -378 -27 -4
-678 -6 -1446 -6 -1231 0 -1398 -2 -1409 -15 -7 -8 -18 -43 -26 -77 -7 -35
-24 -106 -39 -158 -35 -127 -54 -198 -65 -240 -5 -19 -20 -78 -35 -130 -14
-52 -31 -113 -36 -135 -6 -22 -14 -49 -19 -60 -14 -37 -33 -119 -38 -162 l-4
-43 1771 0 1770 0 12 23 c7 12 24 33 38 48 37 36 85 92 91 105 3 6 23 33 45
59 77 91 120 144 155 190 19 26 49 64 65 85 27 35 90 120 195 265 131 179 170
235 170 239 0 3 28 47 63 97 34 51 71 106 82 124 27 42 103 165 118 190 7 11
27 41 45 68 17 26 32 49 32 52 0 2 18 34 40 70 22 36 40 67 40 69 0 2 19 37
42 78 58 100 63 108 146 272 l73 144 -4 133 c-5 160 -30 279 -82 380 -30 58
-121 168 -166 202 -81 59 -110 77 -122 77 -7 0 -21 6 -32 13 -50 34 -213 73
-377 92 -65 8 -122 16 -128 20 -6 3 -364 9 -798 11 -433 3 -820 10 -860 15
-146 18 -227 94 -247 232 -8 49 7 177 30 257 7 25 18 65 25 90 18 66 79 209
104 245 93 133 206 200 376 224 61 9 412 13 1250 14 l1165 2 26 85 c15 47 41
139 59 205 17 66 40 149 50 185 10 36 31 112 45 170 15 58 40 150 55 205 16
55 30 114 32 130 l3 30 -1415 1 c-778 1 -1437 -2 -1465 -6z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
